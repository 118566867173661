.container-fluid .contact {
    color: rgb(4, 118, 244);
}
.container-fluid .contact:hover {
    text-decoration: underline;
}
#social {
    display: flex;
    justify-content: space-around;
}
#social a {
    font-size: 3rem;
    color: white;
}
#social-container {
    height: 200px;
    background-color: rgb(110, 0, 0);
}
.fa-instagram:hover {
    text-decoration: none;
}
.picture-links {
    display: block;
    height: 450px;
    background-size: cover;
    background-origin: content-box;
    background-repeat: no-repeat;
}
.picture-links:hover {
    transform: scale(0.9);
    transition: transform 1s;
}
.video {
    background-image: url(../imgs/video.jpg);
    background-position: top;
    padding-left: 7.5px;
}
.lesson {
    background-image: url(../imgs/shop.jpg);
    background-position: center;
    padding-right: 7.5px;
}
.col-12 h3 {
    background-color: black;
    padding: 5px;
    color: white;
}
@media (max-width: 1050px) {
    #header-gradient h1 {
        font-size: 3rem;
    }
    #header-gradient p {
        font-size: 1.5rem;
    }
    header {
        height: 650px;
    }
}
@media (max-width: 850px) {
    #header-gradient h1 {
        font-size: 2.5rem;
    }
    #header-gradient p {
        font-size: 1.2rem;
    }
    header {
        height: 600px;
    }
}
@media (max-width: 650px) {
    #header-gradient h1 {
        font-size: 2rem;
    }
    #header-gradient p {
        font-size: 1rem;
    }
    header {
        height: 400px;
    }
}
@media (max-width: 550px) {
    #header-gradient h1 {
        font-size: 1.7rem;
    }
    #header-gradient p {
        font-size: 1rem;
    }
    header {
        height: 400px;
    }
}
@media (max-width: 450px) {
    #header-gradient h1 {
        font-size: 1.2rem;
    }
    #header-gradient p {
        font-size: 0.9rem;
    }
    header {
        height: 350px;
    }
}
@media (max-width: 767px) {
    .lesson {
        padding-right: 15px;
    }
    .video {
        padding-left: 15px;
    }
}
@media (min-width: 650px) {
    h2 {
        font-size: 3rem;
    }
    h3 {
        font-size: 2rem;
    }
    #contact-info {
        font-size: 1.5rem;
    }
}